/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import LogRocket from "logrocket";
import setupLogRocket from "logrocket-react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import App from "./App";

import {
  FeatureFlagsProvider,
  StaticFeatureFlagsProvider
} from "./contexts/feature-flags.provider";

const isFlagIdProvided = Boolean(process.env.REACT_APP_FLAGS_CLIENT_SIDE_ID);

LogRocket.init(`${process.env.REACT_APP_LOG_ROCKET_PROJECT}`);
setupLogRocket(LogRocket);

if (isFlagIdProvided) {
  (async () => {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: process.env.REACT_APP_FLAGS_CLIENT_SIDE_ID || ""
    });
    ReactDOM.render(
      <React.StrictMode>
        <LDProvider>
          <Provider store={store}>
            <FeatureFlagsProvider>
              <App />
            </FeatureFlagsProvider>
          </Provider>
        </LDProvider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  })();
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <StaticFeatureFlagsProvider>
          <App />
        </StaticFeatureFlagsProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

reportWebVitals(() => {});
