import React, { useContext } from "react";
import { Navigate, Route } from "react-router-dom";
import Login from "../../login/login";
import Users from "../../users/users";
import Incidents from "../../incident-list/incident-list";
import Incident from "../../incident/incident";
import Organizations from "../../organizations/organizations";
import Organization from "../../organizations/organization/organization";
import Profile from "../profile/profile";
import Authorize from "../../authorize/authorize";
import Logout from "../../logout/logout";
import EventDetails from "../../event/eventDetails/eventDetails";
import {
  Guards,
  REDIRECT_SESSION_STORAGE
} from "../../constants/common.constants";
import SensorsList from "../../sensors-list/sensors-list";
import { SensorsListProvider } from "../../contexts/sensors-list.context";
import { SensorsListFiltersProvider } from "../../contexts/sensors-list-filters.context";
import Guard from "./Guard";
import {
  AUTHORIZE_URL,
  DIFFERENT_ORGANIZATION_USER_DETAILS_URL,
  INCIDENTS_URL,
  PANTHER_EVENTS_URL,
  INCIDENT_DETAILS_URL,
  INCIDENT_URL,
  LOGIN_URL,
  LOGOUT_URL,
  NEW_ORGANIZATION_URL,
  ORGANIZATIONS_DETAILS_URL,
  ORGANIZATIONS_URL,
  ORGANIZATION_USERS_URL,
  OWN_ORGANIZATION_USER_DETAILS_URL,
  ROOT_URL,
  SENSORS_URL,
  SETTINGS_URL,
  USERS_URL
} from "../../constants/urls.constants";
import featureFlagsContext from "../../contexts/feature-flags.provider";

function useMdrRouting(loggedIn: boolean) {
  const pendingUrl: string | null = sessionStorage.getItem(
    REDIRECT_SESSION_STORAGE
  );
  const { mdrEnablePantherIntegration } = useContext(featureFlagsContext);

  return !loggedIn ? (
    <>
      <Route path={AUTHORIZE_URL} element={<Authorize />} />
      <Route path={LOGOUT_URL} element={<Logout />} />
      <Route path={ROOT_URL} element={<Login />} />
      <Route path={LOGIN_URL} element={<Login />} />
    </>
  ) : (
    <>
      <Route
        path={INCIDENTS_URL}
        element={
          <Guard>
            <Incidents />
          </Guard>
        }
      />
      {mdrEnablePantherIntegration && (
        <Route
          path={PANTHER_EVENTS_URL}
          element={
            <Guard>
              <Incidents />
            </Guard>
          }
        />
      )}
      <Route
        path={INCIDENT_URL}
        element={
          <Guard>
            <Incident />
          </Guard>
        }
      />
      <Route
        path={INCIDENT_DETAILS_URL}
        element={
          <Guard guards={[Guards.incidentCompleteDetails]}>
            <EventDetails />
          </Guard>
        }
      />
      <Route
        path={SENSORS_URL}
        element={
          <Guard>
            <SensorsListFiltersProvider>
              <SensorsListProvider>
                <SensorsList />
              </SensorsListProvider>
            </SensorsListFiltersProvider>
          </Guard>
        }
      />
      <Route
        path={ORGANIZATIONS_URL}
        element={
          <Guard guards={[Guards.organizationsList]}>
            <Organizations />
          </Guard>
        }
      />
      <Route
        path={ORGANIZATIONS_DETAILS_URL}
        element={
          <Guard>
            <Organization key={0} />
          </Guard>
        }
      />
      <Route
        path={NEW_ORGANIZATION_URL}
        element={
          <Guard guards={[Guards.createOrganization]}>
            <Organization key={1} />
          </Guard>
        }
      />
      <Route
        path={USERS_URL}
        element={
          <Guard guards={[Guards.usersList]}>
            <Users key={0} />
          </Guard>
        }
      />
      <Route
        path={ORGANIZATION_USERS_URL}
        element={
          <Guard guards={[Guards.usersList]}>
            <Users key={1} />
          </Guard>
        }
      />
      <Route
        path={SETTINGS_URL}
        element={
          <Guard>
            <Profile key={0} />
          </Guard>
        }
      />
      <Route
        path={OWN_ORGANIZATION_USER_DETAILS_URL}
        element={
          <Guard guards={[Guards.userDetails]}>
            <Profile key={1} />
          </Guard>
        }
      />
      <Route
        path={DIFFERENT_ORGANIZATION_USER_DETAILS_URL}
        element={
          <Guard guards={[Guards.userDetails]}>
            <Profile key={2} />
          </Guard>
        }
      />
      <Route
        path={ROOT_URL}
        element={
          <Navigate
            to={pendingUrl && pendingUrl !== "/" ? pendingUrl : INCIDENTS_URL}
            replace
          />
        }
      />
    </>
  );
}
export default useMdrRouting;
