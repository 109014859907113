/* eslint-disable import/prefer-default-export */
export const ROOT_URL = "/";
export const WILDCARD_URL = "*";
export const INCIDENTS_URL = "incidents";
export const PANTHER_EVENTS_URL = "panther-events";
export const HOME_URL = INCIDENTS_URL;
export const AUTHORIZE_URL = "authorize";
export const LOGOUT_URL = "logout";
export const LOGIN_URL = "login";
export const INCIDENT_URL = "incidents/:id";
export const INCIDENT_DETAILS_URL =
  "incidents/:incidentId/events/:eventId/detections/:detectionId";
export const SENSORS_URL = "sensors";
export const ORGANIZATIONS_URL = "organizations";
export const ORGANIZATIONS_DETAILS_URL = "organizations/:id";
export const NEW_ORGANIZATION_URL = "organizations/new";
export const USERS_URL = "users";
export const ORGANIZATION_USERS_URL = "organizations/:id/users";
export const SETTINGS_URL = "settings";
export const OWN_ORGANIZATION_USER_DETAILS_URL = "users/:userId";
export const DIFFERENT_ORGANIZATION_USER_DETAILS_URL =
  "organizations/:orgId/settings/:userId";
