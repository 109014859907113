import { useFlags } from "launchdarkly-react-client-sdk";
import React, { createContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { IState } from "../types/common.types";
import { ROLES } from "../constants/common.constants";

export type FeatureFlagContext = {
  mdrEnablePantherIntegration: boolean;
};
const featureFlagsContext = createContext<FeatureFlagContext>(
  {} as FeatureFlagContext
);

function convertFeatureFlagStringToBoolean(featureFlagString = ""): boolean {
  return featureFlagString?.toLocaleLowerCase() === "true" || false;
}

export const FEATURE_FLAGS = Object.freeze({
  feature_frameworks_mapping: convertFeatureFlagStringToBoolean(
    process.env.REACT_APP_FEATURE_FLAG_FRAMEWORK_MAPPINGS_ENABLED
  ),
  feature_scan_Frequency: convertFeatureFlagStringToBoolean(
    process.env.REACT_APP_FEATURE_FLAG_SCAN_FREQUENCY_ENABLED
  )
});

export function FeatureFlagsProvider({ children }: any) {
  const { profile } = useSelector(({ user }: IState) => user);
  const { mdrEnablePantherIntegration } = useFlags();
  const flags = useMemo(
    () => ({
      mdrEnablePantherIntegration:
        profile.role === ROLES.mdrAdmin && mdrEnablePantherIntegration
    }),
    [mdrEnablePantherIntegration, profile.role]
  );
  return (
    <featureFlagsContext.Provider value={flags}>
      {children}
    </featureFlagsContext.Provider>
  );
}
// this will be used when flag feature management is not available
export function StaticFeatureFlagsProvider({ children }: any) {
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const staticFlags: FeatureFlagContext = {
    mdrEnablePantherIntegration: false
  };
  return (
    <featureFlagsContext.Provider value={staticFlags}>
      {children}
    </featureFlagsContext.Provider>
  );
}

export default featureFlagsContext;
